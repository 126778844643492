@import url('https://fonts.googleapis.com/css?family=Raleway:400,700,800&display=swap');
.container {
    display: flex;
    justify-content: center;
    /* background-image: url(../../../../../ui-components/src/images/CowHerdBackgroundImage.jpg); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    font-family: 'Raleway', sans-serif;
}

.subContainer {
    padding-top: 5%;
}

.labels {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin: 15px 60px;
}

.inputBoxes {
    background-color: #d7d7d7;
    border-radius: 10px;
    border-style: none;
    margin: 5px 0 15px 0;
    min-width: 350px;
    height: 45px;
    text-align: center;
}

.button {
    background-color: #77B82F;
    width: 175px;
    border-radius: 10px;
    color: whitesmoke;
    text-transform: uppercase;
    text-align: center;
    height: 45px;
    font-weight: 700;
    border-style: none;
}
.buttonContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.arrowIcon {
    position: relative;
    left: 10px;
    width: 10px;
    transform: rotate(-90deg);
}

.herdVisionIcon {
    width: 300px;
    height: 60px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 30px
}

.labelText {
    font-weight: 800;
    font-size: 1.7rem;
    margin: 0
}

.paragraphText {
    margin: 0;
    text-align: center;
    font-size: 1.7rem;
}

.inputContainer {
    display: flex;
    justify-content: center;
}

input:focus {
    outline: none;
  }


/* MOBILE DEVICES */
@media only screen and (max-width: 750px) {
    .container {   
        padding: 0 10%;
        background-size: cover;
    }
    .subContainer {
        padding-top: 10%;
    }
    .inputContainer {
        flex-direction: column;
    }
    .labels {
        margin: 0;
    }
    .inputBoxes {
        width: 100%;
        border-radius: 5px;
    }
    .button {
        width: 100%;
        border-radius: 5px;
        margin: 20px 0;
    }
    .paragraphText {
        margin-bottom: 20px;
    }

}

/* MEDIUM DEVICES */
@media only screen and (min-width: 751px) {

    .subContainer {
        padding-top: 10%
    }
    .herdVisionIcon  {
       width: 325px;
    }
    .inputBoxes {
       height: 35px;
       width: 100px;
    }
    .button {
        height: 30px;
    }
}
/* LARGE DEVICES */
