#farms {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#farms td,
#farms th {
  padding: 8px;
}

#farms tr:nth-child(even) {
  background-color: #f2f2f2;
}


#farms tr:hover {
  background-color: #ddd;
}

#farms th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
}

#openVpnClientList {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#openVpnClientList td,
#openVpnClientList th {
  border: 1px solid #ddd;
  padding: 8px;
}

#openVpnClientList tr:nth-child(even) {
  background-color: #f2f2f2;
}

#openVpnClientList tr:hover {
  background-color: #ddd;
}

#openVpnClientList th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
}

#vpnDataConnected {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#vpnDataConnected td,
#vpnDataConnected th {
  border: 1px solid #ddd;
  padding: 8px;
}

#vpnDataConnected tr:nth-child(even) {
  background-color: #f2f2f2;
}

#vpnDataConnected tr:hover {
  background-color: #ddd;
}

#vpnDataConnected th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
}

#openVpnRoutingTableList {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#openVpnRoutingTableList td,
#openVpnRoutingTableList th {
  border: 1px solid #ddd;
  padding: 8px;
}

#openVpnRoutingTableList tr:nth-child(even) {
  background-color: #f2f2f2;
}

#openVpnRoutingTableList tr:hover {
  background-color: #ddd;
}

#openVpnRoutingTableList th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
}

.thead-title {
  display: table-cell;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
}

.sort-col-icon {
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px 0 10px;
}

.notes {
  width: 30%;
  margin: 20px 0;
}

.notes-title {
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
}

.notes-title h3 {
  display: inline;
}

.notes-title span {
  float: right;
  color: #00f;
  cursor: pointer;
}

.notes-list {
  max-height: 400px;
  overflow-y: scroll;
}

.note {
  border: 1px solid #ddd;
  margin: 10px 15px 0 0;
  position: relative;
}

.note-title {
  background-color: #4CAF50;
  color: #fff;
  font-weight: bold;
  text-align: right;
  padding: 10px;
}

.note-text {
  padding: 10px;
}

.note-text q {
  font-style: italic;
}

.note-text textarea,
.note-text textarea:focus {
  margin: 0;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
  font-size: 0.9rem;
  min-height: 30px;
  height: 30px;
  -webkit-appearance: none;
  -moz-apperarance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  resize: vertical;
}

.note-button {
  position: absolute;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
}

.note-button .note-icon {
  display: block;
  color: #fff;
}

.save-button {
  bottom: -10px;
  right: -10px;
  background-color: #4CAF50;
}

.delete-button {
  top: -10px;
  right: -10px;
  background-color: #ff0000;
}

.save-icon {
  padding: 4px 4px 4px 5px;
}

.delete-icon {
  padding: 4px 3px 4px 7px;
}

.submit-cam-ip-button {
  border-radius: 100%;
  color: #fff;
  padding: 1px 2px;
  cursor: pointer;
  margin-left: 1px;
}

.delete-camera-button {
  background-color: red;
  color: white;
  padding: 4px 7px;
  border-radius: 100%;
  cursor: pointer;
}

.search-bar {
  margin: 0px 10px 20px 10px;
  padding: 0 10px;
  height: 30px;
  width: 300px;
  font-size: 1rem;
  border-radius: 10px;
  border: 1px solid gray;
}
.uptime {
  padding: 20px 140px;
}

.filters {
  margin-bottom: 20px;
  display: flex;
  gap: 30px;
  align-items: flex-end;
}

.filters input[type="date"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.filters input[type="date"]:focus {
  border-color: #007BFF;
}

.filters select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
  background-color: #fff;
  width: 156px;
}

.preset-button {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
  background-color: #fff;
  cursor: pointer;
}

.preset-button:hover {
  background-color: #ccc;
}

.reset-dates {
  color: white;
  background-color: grey;
  padding: 6px;
  border-radius: 100%;
  cursor: pointer;
}

.graph-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-end;
  margin-bottom: 20px;
}

.graph-part {
  min-width: 10px;
  display: inline-block;
  margin-left: 3px;
}


.dark {
  color: rgb(211, 211, 211);
}
#farms.dark {
  background-color: #444;
}
#farms.dark tr:nth-child(even) {
  background-color: #363636;
}
#farm.dark tr {
  background-color: #4be64b;
}
#farms.dark tr:nth-child(even):hover {
  background-color: #0f0f0fa4;
}
#farms.dark tr:hover {
  background-color: #0f0f0fa4;
}
#farms.dark th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #1c5e1e;
  color: white;
  border: 1px solid #444;
}
.selected-tab {
  border-top: 2px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}